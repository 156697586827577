import { useRef } from "react";
import emailjs from '@emailjs/browser';

const Join = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_7r4sump', 'template_axmhqp7', form.current, 'jMEEvFs6mZRhveOPV')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });};
    return ( 
        <div className="join" id="join-us">
            <div className="left-j">
                <div>
                    <span className="stroke-text">READY TO</span>
                    <span> LEVEL UP</span>
                </div>
                <div>
                    <span>YOUR BODY</span>
                    <span className="stroke-text"> WITH US ?</span>
                </div>
            </div>
            <div className="right-j">
                <form ref={form} className="email-c" onSubmit={sendEmail}>
                    <input type="email" name="user_email" placeholder="Entre Your Email Address" />
                    <input type="text" name="message" id="m" placeholder="Entre Your Message" />
                <button className="btn btn-j" >Join Now</button>
                </form>
            </div>
        </div>
     );
}
 
export default Join;