import Header from "./Header";
import Heart from '../assets/heart.png'
import hero_image from '../assets/hero_image.png'
import hero_image_back from '../assets/hero_image_back.png'
import Calories from '../assets/calories.png'
import {motion} from 'framer-motion'

const transition = {type : "tween" ,duration : '3'}
const Hero = () => {
    return ( 
        <div className="hero">
            <div className="blur blur-hero"></div>
            <div className="left-h">
                <Header/>
                <div className="ad">
                    <motion.div
                    initial = {{left : '238px'}}
                    whileInView = {{left : '8px'}}
                    transition = {transition}
                    ></motion.div>
                    <span>The best fitness club in the town</span>
                </div>
                <div className="hero-text">
                    <div>
                        <span className="stroke-text">Shape </span>
                        <span>Your</span>
                    </div>
                    <div>
                        <span>ideal body</span>
                    </div>
                    <div>
                        <span>
                        here we will help you to shape and build your ideal body
                        <br /> live up your life to fullest
                        </span>
                        
                    </div>
                </div>
                <div className="figures">
                    <div>
                        <span>+140</span>
                        <span>Expert coaches</span>
                    </div>
                    <div>
                        <span>+978</span>
                        <span>membres joined</span>
                    </div>
                    <div>
                        <span>+50</span>
                        <span>fitness programs</span>
                    </div>
                </div>
                <div className="hero-btn">
                    <div className="btn">Get Started</div>
                    <div className="btn">Learn More</div>
                </div>
            </div>
            <div className="right-h">
                <button className="btn">join now</button>
                <motion.div 
                initial={{right:'1rem'}}
                whileInView={{right:'4rem'}}
                transition={transition}
                className="heart-rate">
                    <img src={Heart} alt="" />
                    <span>Heart Rate</span><span>116 bpm</span>
                </motion.div>
                <img src={hero_image} alt="" className="hero-img"/>
                <motion.img 
                initial={{right:'11rem'}}
                whileInView={{right:'20rem'}}
                transition={transition}
                src={hero_image_back} alt="" className="hero-img-back"/>

                <motion.div 
                initial={{right:'42rem'}}
                whileInView={{right:'32rem'}}
                transition={transition}
                className="calories">
                    <img src={Calories} alt="" />
                    <span>Calories Burned</span><span>220 Kcal</span>
                </motion.div>
            </div>
        </div>
     );
}
 
export default Hero;