import { plansData } from "../data/plansData";
import whiteTick from '../assets/whiteTick.png'
import rightArrow from '../assets/rightArrow.png'


const Plans = () => {

    return ( 
        <div className="plans_c">
            <div className="blur blur-plan1"></div>
            <div className="blur blur-plan2"></div>
            <div className="programs-h">
                <span className="stroke-text">READY TO START</span>
                <span className="s">YOUR JOURNEY</span>
                <span className="stroke-text">NOW WITH US</span>
            </div>
            <div className="plans">
               {plansData.map((plan,i)=>(
                <div className="plan" id='{i}'>
                    {plan.icon}
                    <span>{plan.name}</span>
                    <span>${plan.price}</span>
                    <div className="features">
                        {plan.features.map((feature,i)=>(
                            <div className="feature">
                                <img src={whiteTick} alt="" />
                                <span id='{i}'>{feature}</span>
                            </div>
                        ))}
                    </div>
                    <div><span>See more benefits <img src={rightArrow} alt="" /></span></div>
                    <button className="btn">Join Now</button>
                </div>
               ))}
            </div>
        </div>
     );
}
 
export default Plans;