import Github from '../assets/github.png'
import Instagram from '../assets/instagram.png'
import Linkedin from '../assets/linkedin.png'
import Logo from '../assets/logo.png'
const Footer = () => {
    return ( 
        <div className="footer-c">
            <hr />
           <div className="social">
           <div className="footer">
            <img src={Github} alt="" className='github'/>
            <img src={Instagram} alt="" className='instagram' />
            <img src={Linkedin} alt="" className='linkedin' />
           </div>
           <div className="logo-f">
            <img src={Logo} alt="" />
           </div>
           </div>
           <div className="blur blur-f1"></div>
           <div className="blur blur-f2"></div>
        </div>
     );
}
 
export default Footer;