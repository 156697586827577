import Logo from '../assets/logo.png'
import Bars from '../assets/bars.png'
import Exit from '../assets/exit.png'
import { useState } from 'react';
import {Link} from "react-scroll"
const Header = () => {

    const mobile = window.innerWidth <= 425 ? true : false
    const  [menuOpened,setmenuOpened] = useState(false)
    return ( 
        <div className="header">
            <img src={Logo} alt="" className='logo'/>
            {menuOpened === false && mobile === true ? (
            <div onClick={()=>setmenuOpened(true)}>
                <img src={Bars} alt="" style={{width:'1.5rem',height:'1.5rem'}}/>
            </div>
            )
             
            :(
                <ul className='header_list'>
                <div className="header-menu">
                  <li><Link to='hero'>Home</Link></li>
                  <li><Link to='programs'>Programs</Link></li>
                  <li><Link to='reasons'>Why us</Link></li>
                  <li><Link to='plans'>Plans</Link></li>
                  <li><Link to='testimonials' >Testimonials</Link></li>
                </div>
                <div className="img">
                    <img onClick={()=>setmenuOpened(false)}
                    src={Exit} alt="" className='exit'style={{width:'1.5rem',height:'1.5rem'}} />
                </div>
            </ul>
            )
            
        }
            
        </div>
     );
}
 
export default Header;